import '../styles/Blog.css';
import BlogPostCard from "../components/BlogPostCard"

function Blog() {
    return (
        <div className="blog-posts">
            <BlogPostCard />
        </div>
    );
}

export default Blog;